<template>
  <div class="container">
    <div class="footer__top">
      <div class="footer__top-logo">
        <AImage
          class="footer__logo"
          src="/assets/images/logo.png"
          alt="logo"
          width="132"
          height="24"
          from-public
        />
        <AButton
          v-if="locale === 'ru'"
          class="i-city-dark footer__top-location"
          @click="openModalCity"
        >
          {{ currentRegionName }}
        </AButton>
      </div>

      <button
        v-if="hasShops"
        class="footer__button i-location-grey"
        @click="() => openShopModal()"
      >
        {{ $t("organisms.footer.map") }}
      </button>
    </div>

    <div class="footer__items">
      <ServerRender is="FooterLinks"/>

      <div class="footer__item last">
        <a
          v-if="contacts?.telephone"
          :href="contacts?.supportPhoneHref"
          class="footer__phone"
        >
          <p class="footer__item-heading">
          <span class="footer__contact-title ">
            {{ $t("organisms.footer.support") }} {{ contacts?.workingHours?.pb_default?.timeFrom }} - {{ contacts?.workingHours?.pb_default?.timeTo }}
          </span>
            <span class="footer__contact-phone">
          {{ contacts.telephone }}
        </span>
          </p>
        </a>
        <ul class="footer__link-group">
          <li class="footer__link"  @click="openModal('request-call')">
            <ASocial type="phone" />
            <div class="footer__contact-text">
              {{ $t("organisms.footer.request-call") }}
            </div>
          </li>
          <template v-if="contacts?.onlineChatsList">
            <li
              v-for="item in contacts.onlineChatsList"
              :key="item.code"
              class="footer__link"
              @click="goToSocial(item.link)"
            >
              <ASocial :type="(item.name.toLowerCase() as SocialType)" />
              <ALink>{{ item.name }}</ALink>
            </li>
          </template>
          <li class="footer__link">
            <ASocial type="vk" />
            <ALink
              blank
              prevent
              href="https://vk.com/winestyle"
            >
              {{ $t("organisms.footer.vk") }}
            </ALink>
          </li>
        </ul>

        <button
          v-if="hasShops"
          class="footer__button i-location-grey"
          @click="() => openShopModal()"
        >
          {{ $t("organisms.footer.map") }}
        </button>
      </div>
    </div>

    <!-- <div v-if="contacts" class="footer__contact-list">
      <span class="footer__contact-title">{{ $t("organisms.footer.support") }} {{ contacts.workingHours?.pb_default?.timeFrom }} - {{ contacts.workingHours?.pb_default?.timeTo }}  </span>
      <a
        v-if="contacts.telephone"
        :href="formatTelephone(contacts.telephone)"
        class="footer__contact-phone"
      >{{ contacts.telephone }}</a>

      <div class="footer__contact-item">
        <ASocial type="phone" />
        <div
          class="footer__contact-text"
          @click="openModal('request-call')"
        >
          {{ $t("organisms.footer.request-call") }}
        </div>
      </div>
      <div v-for="item in contacts.onlineChatsList" :key="item.code" class="footer__contact-item">
        <ASocial
          :type="(item.name.toLowerCase() as SocialType)"
        />
        <a
          class="footer__contact-text"
          :href="item.link"
          target="_blank"
          rel="noopener"
        >
          {{ item.name }}
        </a>
      </div>
    </div> -->

    <RenderCacheable
      :max-age="3600"
      cache-key="footer-social"
      :cache-tags="['countries']"
      class="footer__social"
    >
      <div class="footer__social-wrap">
        <div class="footer__copyright-text">
          &#169; WineStyle 2006 — 2024
        </div>

        <MCountrySwitch
          position="bottom-end"
          class="footer__language"
        />

        <MLanguageSwitch
          v-if="isLangSwitching"
          position="bottom-end"
        />
      </div>
    </RenderCacheable>

    <RenderCacheable
      :max-age="3600"
      cache-key="footer-copyright"
      :cache-tags="['countries']"
      class="footer__copyright"
    >
      <div class="footer__copyright-text">
        &#169; WineStyle 2006 — 2024
      </div>

      <div class="footer__copyright-menu">
        <ALink :href="getUrl('/pages/legal.html', false)">
          {{ $t("organisms.footer.terms") }}
        </ALink>

        <ALink
          size="sm"
          :href="getUrl('/pages/map.html', false)"
        >
          {{ $t("organisms.footer.site-map") }}
        </ALink>

        <RegionOnly city-code="ru-mow">
          <MCountrySwitch
            position="bottom-end"
            class="footer__language"
          />
        </RegionOnly>

        <RegionOnly v-if="isLangSwitching" city-code="ru-mow">
          <MLanguageSwitch position="bottom-end"/>
        </RegionOnly>
      </div>
    </RenderCacheable>
  </div>
  <div class="footer__fsrar">
    <div class="container">
      <div>
        <div>
          {{ $t("other.age") }}
        </div>
        {{ $t("other.warning") }}
      </div>
      <div v-if="fsrar">
        {{ fsrar }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineComponent, computed } from 'vue'
import { useLocalization, useModals } from '#imports'

import { useNavigate } from '@/composables/useNavigate'
import { useSupportInfoStore } from '@/stores/marketing'
import { useConfig } from '@/stores/config'
import { useShopStore } from '@/stores/shop'
import { useMainStore } from '@/stores'

import type { SocialType } from '@/components/atoms/Social/types'

import ALink from '@/components/atoms/Link/ALink.vue'
import ASocial from '@/components/atoms/Social/ASocial.vue'
import AButton from '@/components/atoms/Button/AButton.vue'
import AImage from '@/components/atoms/Image/AImage.vue'
import MCountrySwitch from '@/components/ui-kit/m-language/CountrySwitch.vue'
import MLanguageSwitch from '@/components/ui-kit/m-language/LanguageSwitch.vue'
import RegionOnly from '@/components/helpers/RegionOnly'
import ServerRender from '@/components/helpers/ServerRender'

defineComponent({ name: 'OFooter' })

const { getSupportInfo } = useSupportInfoStore()
const { isLangSwitching, currentRegionName, isMscRegion } = useConfig()
const { locale } = useLocalization()
const { getShops, shopListData } = useShopStore()
const { openModal } = useModals()
const { getUrl } = useNavigate()
const { openModalCity } = useMainStore()
const { openShopModal, getInfoForFSRAR } = useShopStore()

const MAIN_STORE_CODE = 'lyusinovskaya-shop'

const [{ data: contacts }, { data: hasShops }, { data: shopRequisites }] = await Promise.all([
  useHydrationData('support-info', getSupportInfo, { lazy: true }),
  useHydrationData('shops-has-any', async () => {
    const data = await getShops()

    return data.length > 0
  }),
  isMscRegion.value
    ? useHydrationData(`shops-requisites-${MAIN_STORE_CODE}`, async () => {
      await getShops()
      const shopCodes = shopListData.value.map(el => el.code)
      let requisites: string[][] = []

      const data = await getInfoForFSRAR(shopCodes).catch(() => { undefined })

      requisites = data?.filter?.((req: string[]) => req[0] === MAIN_STORE_CODE) ?? []

      return requisites
    })
    : { data: undefined }
])

const fsrar = computed(() => isMscRegion.value ? shopRequisites?.value?.[0]?.[1]?.toUpperCase?.() : undefined)

const goToSocial = (href: string): void => {
  window.open(href, '_blank')?.focus()
}
</script>

<style lang="postcss">
.footer {
  width: 100%;
  height: 100%;
  padding-top: var(--spacer-lg);
  background-color: var(--color-neutral-900);

  &__contact-text {
    cursor: pointer;
  }

  &__top {
    &-location {
      &::before {
        margin-right: var(--spacer-4xs);
      }
    }

    &-logo {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & .button {
        background: none;
        color: var(--color-neutral-600);

        @mixin text-sm;
      }
    }

    .link {
      display: flex;
      align-items: center;
      color: var(--color-neutral-600);
      font-size: var(--fs-sm);
    }

    .icon {
      margin-right: var(--spacer-4xs);
    }

    .footer__button {
      margin-top: var(--spacer-xs);
    }

    @media (--screen-lg) {
      display: none;
    }
  }

  &__logo {
    width: 132px !important;
    height: 24px;
    filter: brightness(0) invert(1);
  }

  &__items {
    @media (--screen-lg) {
      display: flex;
    }
  }

  &__language {
    @media (--screen-lg) {
      margin-right: var(--spacer-lg);
    }
  }

  &__link-group {
    overflow: hidden;
    max-height: 0;
    border-bottom: 1px solid var(--color-neutral-800);
    transition: max-height 0.3s cubic-bezier(0, 1, 0.5, 1) 0s;

    & .action-btn {
      display: block;
      margin-bottom: var(--spacer-3xs);
      color: var(--color-neutral-600);

      & .action-btn__inner {
        display: flex;
      }

      @media (hover: hover) and (--screen-lg) {
        &:hover {
          color: var(--color-white);
        }
      }

      @media (--screen-xs) {
        margin-bottom: 0;
        padding: var(--spacer-4xs) 0;

        &:last-child {
          padding-bottom: var(--spacer-xs);
        }
      }
    }

    .footer__link {
      &:first-child {
        @media (--screen-lg) {
          margin-top: 0;
        }
      }

      @media (hover: hover) and (--screen-lg) {
        &:hover > .i-location-grey::before {
          background-image: url("/assets/icons/general.svg#location-white");
        }
      }
    }

    .collapsed {
      overflow: hidden;
      max-height: 0;
      transition: max-height 0.2s;
    }

    .footer__btn-more span {
      display: none;
    }

    &.open {
      .collapsed {
        max-height: 2500px;
        transition: max-height 1s;
      }

      .footer__btn-more {
        p {
          display: none;
        }

        span {
          display: block;
        }
      }
    }

    @media (--screen-lg) {
      border-bottom: 0;
    }

    @media (--screen-lg) {
      display: block;
      overflow: visible;
      max-height: 3000px;
    }
  }

  &__items {
    margin-top: var(--spacer-xs);

    & > div:first-child {
      @media (--screen-lg) {
        display: flex;
        width: 100%;
        margin-right: var(--spacer-base);
      }
    }

    @media (--screen-lg) {
      margin-top: 0;
    }
  }

  &__item {
    margin-right: calc(var(--spacer-xs) * -1);

    &.last {
      max-width: 16.5rem;
    }

    .footer__item-heading {
      &::before {
        position: absolute;
        right: var(--spacer-xs);
        transition: all 0.2s;
        transform: rotate(180deg);

        @media (--screen-lg) {
          display: none;
        }
      }
    }

    &.open {
      .footer__link-group {
        max-height: 3000px;
        transition: max-height 0.3s cubic-bezier(1, 0, 1, 0.5) 0s;
      }

      .footer__item-heading {
        border-color: transparent;

        &::before {
          position: absolute;
          right: var(--spacer-xs);
          transform: none;
        }
      }

      .collapsed {
        & .footer__link:last-child {
          padding-bottom: 0;
        }
      }
    }

    &.last {
      & .footer__link-group {
        max-height: 1000px;
        border-bottom: none;
      }

      @media (--screen-xs) {
        & .footer__item-heading {
          flex-direction: column;
          align-items: flex-start;
          padding-top: var(--spacer-lg);
        }

        & .footer__link {
          display: flex;
          margin-bottom: var(--spacer-3xs);

          & .social {
            margin-right: var(--spacer-2xs);
          }
        }

        & > a:last-child {
          display: none;
        }
      }
    }

    &:last-child {
      min-width: 10.9375rem;
      margin-right: 0;
      margin-left: auto;

      @media (--screen-lg) {
        & .footer__contact-title {
          display: none;
        }

        & .social {
          display: none;
        }
      }
    }

    .footer__item-heading {
      display: flex;
      justify-content: space-between;
      padding-top: var(--spacer-xs);
      padding-right: var(--spacer-xs);
      color: var(--color-white);

      @mixin text-base-bold;

      @media (--screen-lg) {
        margin-bottom: var(--spacer-xs);
        padding: 0;
        border: none;

        .icon {
          display: none;
        }
      }

      @media (--screen-xs) {
        padding-bottom: var(--spacer-xs);
      }
    }

    @media (--screen-xs) {
      display: flex;
      flex-direction: column;

      .footer__button {
        display: none;
      }

      &.last {
        & .footer__link {
          &:last-child {
            padding: var(--spacer-4xs) 0;
          }
        }
      }

      &:last-child {
        max-width: 100%;
        margin-left: 0;
        padding-bottom: 0;
        border-bottom: none;

      }
    }

    @media (--screen-lg) {
      /* max-width: 16.5rem; */
      width: 100%;
      min-width: 10.875rem;
      margin-right: var(--spacer-base);
    }
  }

  &__phone {
    & p {
      color: var(--color-neutral-300);

      @media (hover: hover) and (--screen-lg) {
        &:hover {
          color: var(--color-white);
        }
      }
    }
  }

  &__link {
    margin-bottom: var(--spacer-3xs);

    & .link {
      display: flex;
      align-items: center;
      color: var(--color-neutral-600);

      &.link--text {
        cursor: pointer;
      }

      .badge {
        margin-left: var(--spacer-4xs);
      }

      @media (hover: hover) and (--screen-lg) {
        &:hover {
          color: var(--color-white);
        }
      }
    }

    .link::before {
      width: 16px;
      height: 16px;
      margin-right: var(--spacer-4xs);
    }

    .hide {
      display: none;
    }

    @media (--screen-xs) {
      margin-bottom: 0;
      padding: var(--spacer-4xs) 0;

      &:last-child {
        padding-bottom: var(--spacer-xs);
      }
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 40px;
    margin-top: var(--spacer-sm);
    border: 1px solid var(--color-neutral-500);
    border-radius: var(--border-radius-xs);
    color: var(--color-neutral-600);
    user-select: none;

    &::before {
      width: 16px;
      height: 16px;
      margin-right: var(--spacer-4xs);
    }

    @mixin text-base-bold;

    @media (hover: hover) and (--screen-lg) {
      &:hover {
        border-color: var(--color-white);
        color: var(--color-white);
        cursor: pointer;

        &.i-location-grey::before {
          background-image: url("/assets/icons/general.svg#location-white");
        }
      }
    }
  }

  &__social-btn {
    display: flex;
    margin-top: var(--spacer-lg);

    a {
      display: block;
      font-size: 0;
    }

    .icon-button {
      margin-right: var(--spacer-3xs);

      @media (hover: hover) and (--screen-lg) {
        &:hover.i-social-vk-grey::before {
          background-image: url("/assets/icons/general.svg#social-vk");
        }
      }
    }

    @media (--screen-xs) {
      margin-top: var(--spacer-2xs);
    }
  }

  &__copyright {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: var(--spacer-xs);
    padding-bottom: var(--spacer-xs);

    &-text {
      display: none;
      color: var(--color-neutral-600);

      @mixin text-sm;

      @media (--screen-lg) {
        display: block;
      }
    }

    .m-country {
      margin-right: var(--spacer-3xs);
    }

    &-menu {
      position: relative;
      width: 100%;

      & > div {
        display: none;

        @media (--screen-lg) {
          display: inline-block;
        }
      }

      @media (--screen-lg) {
        width: auto;
      }

      @media (--screen-xs) {
        & .link:last-child {
          margin-bottom: 0;
        }
      }
    }

    .link {
      display: block;
      width: 100%;
      margin-right: var(--spacer-lg);
      margin-bottom: var(--spacer-4xs);
      color: var(--color-neutral-600);

      @mixin text-sm;

      @media (--screen-lg) {
        display: inline-block;
        width: auto;
        margin-bottom: 0;
      }

      @media (hover: hover) and (--screen-lg) {
        &:hover {
          color: var(--color-white);
        }
      }
    }

    @media (--screen-lg) {
      margin-top: var(--spacer-lg);
      padding-bottom: 0;
    }
  }

  &__contact {
    &-phone {
      @media (--screen-xs) {
        user-select: none;
      }
    }

    &-title {
      color: var(--color-neutral-500);

      @mixin text-sm;

      @media (--screen-xs) {
        margin-bottom: var(--spacer-3xs);
      }
    }

    &-item {
      display: flex;
      align-items: center;
      margin-bottom: var(--spacer-xs);
    }

    &-text {
      display: flex;
      align-items: center;
      color: var(--color-neutral-500);

      &:hover {
        color: #fff;
      }
    }
  }

  &__social {
    display: block;
    margin-top: var(--spacer-base);
    margin-right: calc(var(--spacer-xs) * -1);
    margin-left: calc(var(--spacer-xs) * -1);
    padding: var(--spacer-3xs) var(--spacer-xs);
    border-top: 1px solid var(--color-neutral-800);
    border-bottom: 1px solid var(--color-neutral-800);

    @media (--screen-xs) {
      margin-top: var(--spacer-xs);

      & .footer__copyright-text {
        display: flex;
        align-items: center;
        width: 100%;
      }

      &-wrap {
        display: flex;
        justify-content: space-between;

        .language {
          padding-right: 0;
        }
      }
    }

    @media (--screen-lg) {
      display: none;
    }
  }

  &__fsrar {
    margin: var(--spacer-base) 0 0;
    padding: var(--spacer-base) 0 0;
    border-top: 1px solid var(--color-neutral-800);

    & > div {
      & > div:first-child {
        display: flex;
        gap: var(--spacer-3xs);
        align-items: baseline;
        color: var(--color-white);

        & > div {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 2rem;
          min-width: 2rem;
          height: 2rem;
          border: 1px solid var(--color-neutral-800);
          border-radius: var(--border-radius-full);

          @mixin text-xs-bold;
        }

        @mixin text-xs;
      }

      & > div + div {
        color: var(--color-neutral-700);
        margin-top: var(--spacer-2xs);

        @mixin text-xs;
      }
    }

    @media (--screen-xs) {
      margin: 0;
      padding: var(--spacer-base) 0;
    }
  }

  @media (--screen-lg) {
    padding: var(--spacer-3xl) 0 var(--spacer-base) 0;
  }
}
</style>
